
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import TitleSubscribeShareHeader from '@/components/common/layout/TitleSubscribeShareHeader.vue'
import { DeleteSubscribe, PostSubscribe } from '@/api/displayAPI/UserAPI'
import { AppProfile, OptionFilterList } from '../../../../types/App'
import Sharing from '@/components/common/dialogs/Sharing.vue'
import Alert from '@/components/common/dialogs/Alert.vue'
import { StoreHomeHistory } from '@/store/StoreHomeStore'
import CategoryTab from '@/components/views/category/common/CategoryTab.vue'
import StoreCardX from '@/components/common/widgets/StoreCardX.vue'
import IconTextButton from '@/components/common/buttons/IconTextButton.vue'
import ProductCardImage from '@/components/common/widgets/ProductCardImage.vue'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import VueSlickCarousel from 'vue-slick-carousel'
import SimpleTitleHeader from '@/components/common/layout/SimpleTitleHeader.vue'
import CategorySortDialog from '@/components/common/dialogs/CategorySortDialog.vue'
import { GetShopInfoV3, GetShopProductList } from '@/api/displayAPI/ShopAPI'
import ProductCardY from '@/components/common/widgets/ProductCardY.vue'
import { adOnTracking } from '@/plugins/util'
import { PostAdOnClick, PostAdOnView } from '@/api/displayAPI/DisplayAPI'
import { onRequestAdonBanner } from '@/helper/banner'

const DEFAULT_PAGE = 0
const DEFAULT_LIMIT = 50

export default Vue.extend<Data, Method, Computed, Props>({
  name: 'InnerStoreHomeTemplate',
  components: {
    ProductCardY,
    CategorySortDialog,
    SimpleTitleHeader,
    ProductCardImage,
    IconTextButton,
    StoreCardX,
    CategoryTab,
    TitleSubscribeShareHeader,
    Sharing,
    Alert,
    VueSlickCarousel,
  },
  props: {
    isModalMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bannerIndex: 0,
      isShowFilterDialog: false,
      shopId: '',
      headerIntersectThreshold: 1,
      requestParam: {} as ShopProductListRequestParam,
      categoryProduct: {} as SearchResultResponse,
      productList: [] as ProductItem[],
      filterTab: 2,
      isFetching: false,
      isShowSharingDialog: false,
      alert: {
        show: false,
        message: '',
      },
      carouselOption: {
        centerMode: true,
        centerPadding: '74px',
        infinite: true,
        slidesToShow: 1,
        speed: 500,
        swipeToSlide: true,
        focusOnSelect: true,
        useCSS: true,
        useTransform: true,
        edgeFriction: 0,
        lazyLoad: 'progressive',
      },
    }
  },
  created() {
    this.shopId = this.$route.query.shopId as string
    if (!this.shopId) return this.$router.push('/404')

    this.requestParam.shopId = this.shopId

    const { init } = this.getStoreHomeHistory
    if (init) {
      this.fetchShopInfo()
      this.executeGetCategoryDetailGroup()
    }
  },
  mounted() {
    const { active } = this.getStoreHomeHistory
    this.$nextTick(() => {
      if (this.slickCarousel) {
        this.slickCarousel.goTo(active)
      }
    })
    this.$getAppHtml.addEventListener(
      'visibilitychange',
      this.handleVisibilityChange,
      false
    )
  },
  beforeDestroy() {
    this.fetchHistoryInit()
    this.$getAppHtml.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange,
      false
    )
  },
  computed: {
    ...mapGetters('ProfileStore', [
      'getProfile',
      'getDefaultFilterIndex',
      'getCategoryList',
      'getFilterList',
    ]),
    ...mapGetters('StoreHomeStore', ['getStoreHomeHistory']),
    ...mapGetters('BannerStore', ['getFlipBanner']),
    shareUrl() {
      return `${process.env.VUE_APP_CELLOOK_URL}/?shopId=${this.shopId}`
    },
    filterTitle() {
      const { filterIndex } = this.getStoreHomeHistory
      return this.getFilterList[filterIndex]?.title ?? ''
    },
    slickCarousel() {
      const slickCarousel: any = this.$refs.slickCarousel
      return slickCarousel
    },
  },
  watch: {
    isLogined() {
      this.fetchShopInfo()
      this.handleRefresh()
    },
  },
  methods: {
    ...mapActions('LoadingStore', ['fetchLoading']),
    ...mapMutations('BridgeStore', ['setShowBridgeForShop']),
    ...mapActions('DialogStore', ['fetchLoginDialog']),
    ...mapActions('StoreHomeStore', [
      'fetchMarginHeight',
      'fetchScroll',
      'fetchHistory',
      'fetchHistoryInit',
    ]),
    onIntersect(entries) {
      this.headerIntersectThreshold = entries[0].intersectionRatio
    },
    executeGetCategoryDetailGroup() {
      //페이징 초기화
      this.refreshPaging()
      // this.requestParam['filters.mallId'] = String(this.shopId)
      this.categoryProduct = {} as SearchResultResponse
      this.productList = []
      this.fetchStoreHomeCategoryProductList(this.requestParam, 'reset')
    },
    categoryTabChange(option: any, index: number) {
      //todo 카테고리 ga 트래킹 추가.
      this.requestParam.categoryCode = option.categoryCode
      this.fetchHistory({
        ...this.getStoreHomeHistory,
        categoryTab: index,
        marginHeight: 0,
      })
      //화면위치 초기화
      this.executeGetCategoryDetailGroup()
    },
    filterChange(index: number, sortOption: OptionFilterList) {
      this.isShowFilterDialog = false

      this.$tracking(
        `storehome_${this.shopId}_filter_${sortOption.filter['sort.option']}`
      )
      this.requestParam.sortOption = sortOption.filter['sort.option']
      this.fetchHistory({
        ...this.getStoreHomeHistory,
        sort: this.requestParam.sortOption,
        filterIndex: index,
        marginHeight: 0,
      })
      //화면위치 초기화
      this.executeGetCategoryDetailGroup()
    },
    refreshPaging() {
      this.requestParam.page = DEFAULT_PAGE
      this.requestParam.limit = DEFAULT_LIMIT
    },
    async fetchShopInfo() {
      try {
        // 이건 확인 최초 init시에
        const { data } = await GetShopInfoV3({ shopId: this.shopId })
        this.fetchHistory({
          ...this.getStoreHomeHistory,
          shopInfo: data,
        })
      } catch (e: any) {
        console.log(e)
        if (e.error_code === 400) {
          this.$router.back()
        }
      }
    },
    async subscribe() {
      try {
        const { shopInfo } = this.getStoreHomeHistory
        if (!this.shopId) return
        await PostSubscribe(this.shopId, shopInfo.shopName)
        const replaceShopInfo = shopInfo
        replaceShopInfo.subscribeCount++
        replaceShopInfo.isSubscribed = true
        this.fetchHistory({
          ...this.getStoreHomeHistory,
          shopInfo: replaceShopInfo,
        })

        this.$tracking(`storehome_${this.shopId}_subcribe`)
      } catch (e: any) {
        if (e && e.error_code === 400) {
          this.alert = { show: true, message: e.message }
        }
      }
    },
    async unSubscribe() {
      try {
        const { shopInfo } = this.getStoreHomeHistory
        if (!this.shopId) return
        await DeleteSubscribe(this.shopId, shopInfo.shopName)
        const replaceShopInfo = shopInfo
        replaceShopInfo.subscribeCount--
        replaceShopInfo.isSubscribed = false
        this.fetchHistory({
          ...this.getStoreHomeHistory,
          shopInfo: replaceShopInfo,
        })

        this.$tracking(`storehome_${this.shopId}_unsubcribe`)
      } catch (e) {
        console.log(e)
      }
    },
    async toggleSubscribe() {
      try {
        if (!this.isLogined) {
          return this.fetchLoginDialog({ yn: true })
        }
        const { isSubscribed } = this.getStoreHomeHistory.shopInfo
        isSubscribed ? await this.unSubscribe() : await this.subscribe()
      } catch (e) {
        console.log(e)
      }
    },
    async fetchStoreHomeCategoryProductList(
      requestParam: ShopProductListRequestParam,
      type: string
    ) {
      try {
        this.fetchLoading({ yn: true })
        const { data } = await GetShopProductList(requestParam)
        if (!data.searchProductList) return

        const { list, trendPickList } = this.getStoreHomeHistory

        const replaceList = this.$replaceList(
          list,
          data.advertisementSearchProductList.concat(data.searchProductList),
          this.requestParam.page,
          this.requestParam.limit,
          type
        )

        this.fetchHistory({
          ...this.getStoreHomeHistory,
          list: replaceList,
          trendPickList:
            type === 'reset' ? data.trendPickSearchProductList : trendPickList,
          total: data.paging?.total ?? 0,
        })
      } catch (e) {
        console.log(e)
      } finally {
        this.fetchLoading({ yn: false })

        this.isFetching = false
      }
    },
    goShop() {
      const { shopInfo } = this.getStoreHomeHistory
      this.$tracking(`storehome_${this.shopId}_naverss`)
      this.setShowBridgeForShop(shopInfo)
    },
    goBack() {
      this.$tracking(`storehome_${this.shopId}_back`)
      this.$emit('goBack')
    },
    async handleVisibilityChange() {
      if (document.visibilityState !== 'hidden') {
        this.fetchShopInfo()
      }
    },
    handleRefresh() {
      this.executeGetCategoryDetailGroup()
      if (this.$route.name) onRequestAdonBanner(this.$route.name)
    },
    onRequest(page, type) {
      if (this.isFetching) return
      this.isFetching = true
      this.requestParam.page = page
      this.fetchStoreHomeCategoryProductList(this.requestParam, type)
    },
    handleSharing(yn, type) {
      this.$tracking(`storehome_${this.shopId}_share`)
      this.isShowSharingDialog = yn
    },
    isLikeChange(yn, index) {
      const trackingName = `storehome_${this.shopId}_${this.$numbering(
        index + 1
      )}`

      if (yn) this.$tracking(`${trackingName}_like`)
      else this.$tracking(`${trackingName}_unlike`)
    },
    handleActive(active) {
      this.fetchHistory({
        ...this.getStoreHomeHistory,
        active,
      })
    },
    handleSubscribe(isSubscribe: boolean, shopId: string) {
      const { shopInfo } = this.getStoreHomeHistory
      const replaceShopInfo = shopInfo
      replaceShopInfo.isSubscribed = isSubscribe
      this.fetchHistory({
        ...this.getStoreHomeHistory,
        shopInfo: replaceShopInfo,
      })
    },
    onBannerIntersect(entries: IntersectionObserverEntry[]) {
      if (this.$getAppHtml.scrollTop != 0) return
      if (entries[0].intersectionRatio < 0.99) return

      const { bannerList } = this.getFlipBanner

      adOnTracking(bannerList[this.bannerIndex].bannerViewEventUrl, (key) =>
        PostAdOnView({ key })
      )
    },
    goBannerPage(link: string) {
      const { bannerList } = this.getFlipBanner

      adOnTracking(bannerList[this.bannerIndex].bannerClickUrl, (key) =>
        PostAdOnClick({ key })
      )

      this.$tracking(
        `storehome_banner_${this.$numbering(this.bannerIndex + 1)}`
      )

      const payload = {
        url: link,
        title: 'CELLOOK',
        isGoneBottomView: false,
      }
      this.$rendingOutsidePageOrInternal(this.$router, this.$device, payload)
    },
  },
})

interface Data {
  bannerIndex: number
  isShowFilterDialog: boolean
  headerIntersectThreshold: number
  shopId: string
  requestParam: ShopProductListRequestParam
  categoryProduct: SearchResultResponse
  productList: ProductItem[]
  filterTab: number
  isFetching: boolean
  isShowSharingDialog: boolean
  alert: {
    show: boolean
    message: string
  }
  carouselOption: {
    centerMode: boolean
    centerPadding: string
    infinite: boolean
    slidesToShow: number
    speed: number
    swipeToSlide: true
    focusOnSelect: true
  }
}

interface Method {
  fetchHistoryInit(): Promise<void>

  fetchShopInfo(): void

  subscribe(): void

  unSubscribe(): void

  toggleSubscribe(): void

  executeGetCategoryDetailGroup(): void

  refreshPaging(): void

  categoryTabChange(index: number, sortOption: any): void

  filterChange(index: number, sortOption: OptionFilterList): void

  fetchStoreHomeCategoryProductList(
    requestParam: ShopProductListRequestParam,
    type?: string
  ): void

  fetchLoginDialog(payload: { yn: boolean }): void

  setShowBridgeForShop(shop: Shop): void

  goShop(): void

  goBack(): void

  handleVisibilityChange(): void

  onIntersect(entries: any, observer: any): void

  handleRefresh(): void

  onRequest(page: number, type: string): void

  fetchMarginHeight(height: number): void

  fetchScroll(scroll: number): void

  fetchHistory(payload: StoreHomeHistory): void

  handleSharing(yn: boolean, type?: string): void

  isLikeChange(yn: boolean, index: number): void

  fetchLoading(payload: { yn: boolean }): void

  handleActive(active: number): void

  handleSubscribe(isSubscribe: boolean, shopId: string): void

  goBannerPage(link: string): void

  onBannerIntersect(entries: IntersectionObserverEntry[]): void
}

interface Computed {
  getProfile: AppProfile
  getDefaultFilterIndex?: number | undefined
  getCategoryList: CategoryItem[]
  getFilterList: OptionFilterList[]
  shareUrl: string
  getStoreHomeHistory: StoreHomeHistory
  filterTitle: string
  slickCarousel: any
  getFlipBanner: AdonBannerList
}

interface Props {
  isModalMode: boolean
}
