
import Vue from 'vue'
import StoreHomeView from '../../views/storeHome/StoreHome.vue'
export default Vue.extend({
  components: {
    StoreHomeView,
  },
  data() {
    return {}
  },
})
