
import Vue, { PropType } from 'vue'
import { OptionFilterList } from '../../../../types/App'

export default Vue.extend<Data, Method, Computed, Props>({
  name: 'CategorySortDialog',
  props: {
    filter: {
      type: [] as PropType<OptionFilterList[]>,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
    selectedIndex: {
      type: Number,
      required: true,
    },
  },
})
interface Data {}
interface Method {}
interface Computed {}
interface Props {
  filter: OptionFilterList[]
  show: boolean
  selectedIndex: number
}
