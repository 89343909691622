
import Vue from 'vue'

export default Vue.extend<Data, Method, Computed, Props>({
  name: 'IconTextButton',
  props: {
    /**
     * $vuetify.icons.XXXX
     */
    image: {
      type: String,
      required: true,
    },
    /**
     * ex. 공유하기
     */
    text: {
      type: String,
      required: true,
    },
    disable: {
      type: Boolean,
      required: false,
    },
  },
})

interface Data {}
interface Method {}
interface Computed {}
interface Props {
  image: String
  text: String
  disable: boolean
}
