
import Vue from 'vue'
import { mapActions } from 'vuex'
import InnerStoreHomeTemplate from '@/components/views/storeHome/InnerStoreHomeTemplate.vue'
import { StoreHomeHistory } from '@/store/StoreHomeStore'

export default Vue.extend<Data, Methods, Computed>({
  name: 'StoreHome',
  components: {
    InnerStoreHomeTemplate,
  },
  async created() {
    if (localStorage.getItem('storeHomeHistory')) {
      const history = JSON.parse(
        Object(localStorage.getItem('storeHomeHistory'))
      )
      await this.fetchHistory(history)
      const { scroll } = history
      this.$getAppHtml.scrollTo({ top: scroll })

      localStorage.removeItem('storeHomeHistory')
    }
  },
  methods: {
    ...mapActions('StoreHomeStore', ['fetchHistory']),
    moveToBack() {
      this.$router.back()
    },
  },
})

interface Data {}
interface Methods {
  moveToBack(): void
  fetchHistory(payload: StoreHomeHistory): void
}
interface Computed {}
